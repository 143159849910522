import axios from "axios";

const URL = process.env.REACT_APP_API_URL;

const config = {
  headers: {
    "Content-Type": "multipart/form-data", // Example header, you can add more headers as needed
  },
};

const configData = {
  headers: {
    "Content-Type": "application/json", // Example header, you can add more headers as needed
  },
};

const serviceDetailsList = async () => {
  try {
    const result = await axios
      .get(`${URL}/services/get-services-event-list`, {
        headers: {
          "Content-Type": "application/json", // Set content type if needed
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    return error;
  }
};

const serviceDetailsListUpdate = async (id) => {
  try {
    const result = await axios
      .put(`${URL}/services/update-services-event`, id, config)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    return error;
  }
};

export { serviceDetailsList, serviceDetailsListUpdate };
