import React from "react";

const scrollup = () => {

  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ensures a smooth scrolling effect
    });
  };
  return (
    <button onClick={handleScrollUp} id="scroll-top">
      <i className="far fa-arrow-up" />
    </button>
  );
};

export default scrollup;
