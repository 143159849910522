import React, { useEffect, useState } from "react";
import { serviceDetailsList } from "../service/ServiceMaster/ServiceMaster";

const Sports = () => {
  const [sportsData, setSportsData] = useState([]);
  const URL = process.env.REACT_APP_API_URL;
  const baseURL = URL.replace("/v1", "");
  const imageURL = `${baseURL}/images/`;

  const getServicelist = () => {
    serviceDetailsList()
      .then((res) => {
        const dataArray = res?.data?.data || [];
        const lunchItem = dataArray.find(
          (item) => item.servicetype === "Sports"
        );
        if (lunchItem) {
          setSportsData(lunchItem);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getServicelist();
  }, []);

  const getYouTubeEmbedUrl = (url) => {
    if (!url) return "";

    // Extract video ID from standard, shortened, or share URLs
    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&?\/]+)/
    );
    if (!videoIdMatch) return "";

    const videoId = videoIdMatch[1];

    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div className="main">
      <div
        className="site-breadcrumb"
        style={{
          backgroundImage: sportsData?.mainImage
            ? `url(${imageURL}${sportsData?.mainImage})`
            : "", // Fallback image if mainImage is not available
        }}
      >
        <div className="container">
          <h2 className="breadcrumb-title">Sports</h2>
          <ul className="breadcrumb-menu">
            <li>
              <a href="/">Home</a>
            </li>
            <li className="active">Sports</li>
          </ul>
        </div>
      </div>

      <div className="schedule-single py-120">
        <div className="container">
          <div className="schedule-single-wrap">
            <div className="row g-4">
              <div className="col-xl-12 col-lg-12">
                <div className="schedule-detail">
                  {sportsData?.secondImage && (
                    <div className="schedule-detail-img mb-30">
                      <img
                        src={`${imageURL}${sportsData?.secondImage}`}
                        alt="thumb"
                      />
                    </div>
                  )}

                  <div className="schedule-detail-info">
                    <h3 className="mb-20">{sportsData?.title}</h3>
                    <p
                      className="mb-20 contents"
                      dangerouslySetInnerHTML={{
                        __html:
                          sportsData.description &&
                          Array.isArray(sportsData.description) &&
                          sportsData.description[0]
                            ? JSON.parse(sportsData.description[0])
                            : "",
                      }}
                    ></p>
                    {sportsData?.link && (
                      <div className="video-area">
                        <div className="video-content">
                          <div className="video-wrapper">
                            <iframe
                              width="100%"
                              height="100%"
                              src={getYouTubeEmbedUrl(sportsData.link)}
                              title="YouTube video player"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    )}
                    <p
                      className="mb-20 mt-20 contents"
                      dangerouslySetInnerHTML={{
                        __html:
                          sportsData.videodescription &&
                          sportsData.videodescription
                            ? JSON.parse(sportsData.videodescription)
                            : "",
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sports;
