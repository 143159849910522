import axios from "axios";

const URL = process.env.REACT_APP_API_URL;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const configData = {
  headers: {
    "Content-Type": "application/json",
  },
};

const homeDetailsList = async () => {
  try {
    const result = await axios
      .get(`${URL}/home/get-homeDetail-list`, {
        headers: {
          "Content-Type": "application/json", // Set content type if needed
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    return error;
  }
};

export { homeDetailsList };
