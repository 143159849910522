import React, { useEffect, useState } from "react";
import breadcrumb from "../assets/img/about/mainimage.jpg";

import { Link, useNavigate } from "react-router-dom";
import {
  getEventMasterDetails,
  getEventMasterList,
} from "../service/EventMasterService/EventMasterService";

const Event = () => {
  const navigate = useNavigate();
  const URL = process.env.REACT_APP_API_URL;
  const baseURL = URL.replace("/v1", "");
  const imageURL = `${baseURL}/images/`;

  const [eventList, setEvenList] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState("");

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const handleCloseVideo = () => {
    setSelectedVideo("");
  };
  const handleEventDetails = (event) => {
    getEventMasterDetails(event?.url)
      .then((res) => {
        const response = res?.data?.data;
        navigate(`/event/${response.url}`, { state: { response } });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchEventsList = () => {
    getEventMasterList()
      .then((res) => {
        const filteredEvents = res.data.data.filter(
          (event) => event.status === "1"
        );
        setEvenList(filteredEvents);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    fetchEventsList();
  }, []);

  const getYouTubeEmbedUrl = (url) => {
    if (!url) return "";

    // Extract video ID from standard, shortened, or share URLs
    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&?\/]+)/
    );
    if (!videoIdMatch) return "";

    const videoId = videoIdMatch[1];

    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div className="main">
      <div
        className="site-breadcrumb"
        style={{ background: `url(${breadcrumb})` }}
      >
        <div className="container">
          <h2 className="breadcrumb-title">Overview</h2>
          <ul className="breadcrumb-menu">
            <li>
              <Link to="/" />
              Home
            </li>
            <li className="active">Overview</li>
          </ul>
        </div>
      </div>
      <div className="venue-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="site-heading text-center wow fadeInDown"
                data-wow-delay=".25s"
              >
                {/* <span className="site-title-tagline">Overview</span> */}
                <h2 className="site-title">
                  Explore Our <span>Events</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row g-5 wow fadeInUp" data-wow-delay=".25s">
            <p className="contents">
              The executive roundtable events can be customzied to your
              specifications in over 100 cities around the globe. These events
              provide up to 4 hours of stimulating discussions, exceptional
              dining, and cocktails, all within a carefully chosen audience. We
              will work closely with your thought leaders to craft a captivating
              and dynamic agenda centered on the primary business challenges
              your company addresses. Held at top-tier restaurants with
              thoughtfully curated menus and exclusive dining environments, our
              roundtables ensure a refined experience. SuiteLink will oversee
              every detail of the event, including venue selection, audience
              engagement, website development, and attendee coordination,
              guaranteeing a seamless and effortless experience for your team.
            </p>
            <br />
            <ul>
              <li>
                <h3 style={{ margin: "15px 0px 10px 0px" }}>
                  Exclusive and Focused Gathering
                </h3>
                <p className="contents">
                  SuiteLink brings together executives in intimate settings,
                  including lunches, dinners, sporting events that feature
                  panels, debates and discussions. Each event is limited to
                  10-15 attendees, ensuring a focused and exclusive environment.
                </p>
                <br />
              </li>
              <li>
                <h3 style={{ margin: "15px 0px 10px 0px" }}>
                  Meaningful Collaboration for Problem-Solving
                </h3>

                <p className="contents">
                  By fostering close-knit environments, SuiteLink facilitates
                  meaningful collaboration among executives. This approach is
                  designed to address and solve pressing business issues more
                  swiftly through collective insight and shared experience.
                </p>
                <br />
              </li>
              <li>
                <h3 style={{ margin: "15px 0px 10px 0px" }}>
                  Community Building and Ongoing Support{" "}
                </h3>

                <p className="contents">
                  SuiteLink's events go beyond immediate problem-solving by
                  cultivating a robust community of executives. This community
                  offers ongoing support and valuable connections that extend
                  far beyond each session, creating a lasting network of
                  resources and collaboration.
                </p>
              </li>
            </ul>
            <div style={{ display: "contents" }}>
              {eventList.length > 0 ? (
                eventList.map((item, index) => (
                  <div className="col-md-6 col-lg-4" key={index} style={{margin:"10px 0px 10px 0px"}}>
                    <div
                      className="venue-item"
                      onClick={() => handleEventDetails(item)}
                    >
                      <div className="venue-img">
                        <img
                          src={`${imageURL}${item?.image[0]}`}
                          alt="venue-img"
                        />
                      </div>
                      <div
                        className="venue-content"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <span>{item?.title}</span>

                        {item?.link && (
                          <div class="venue-play">
                            <a
                              class="popup-youtube"
                              onClick={() => handleVideoClick(item.link)}
                            >
                              <span>Video</span>
                              <i class="fas fa-play"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12 text-center">
                  {/* <p>No events found</p> */}
                </div>
              )}
              {selectedVideo && (
                <div className="video-overlay">
                  <div className="video-container">
                    <span className="close" onClick={handleCloseVideo}>
                      &times;
                    </span>
                    <iframe
                      width="100%"
                      height="315"
                      src={getYouTubeEmbedUrl(selectedVideo)}
                      frameBorder="0"
                      allowFullScreen
                      title="Video"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
