import React, { useEffect, useState } from "react";
import breadcrumb01 from "../assets/img/about/mainimage.jpg";
import {
  getEventMasterDetails,
  submitRegisteredEventUser,
} from "../service/EventMasterService/EventMasterService";
import Toast from "../../src/util/Toast";
import { Link, useLocation, useParams } from "react-router-dom";
import { contactDetailsList } from "../service/ContactMaster/ContactMaster";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Tooltip } from "react-tooltip";

const EventDetails = () => {
  const location = useLocation();
  const event = location.state?.response;
  const { eventUrl } = useParams();

  const URL = process.env.REACT_APP_API_URL;
  const baseURL = URL.replace("/v1", "");
  const imageURL = `${baseURL}/images/`;

  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    company: "",
    title: "",
    question: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    company: "",
    title: "",
  });

  const [eventData, setEventData] = useState(event || null);
  const [contactData, setContactData] = useState();

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isEventStarted, setIsEventStarted] = useState(false);

  const convertTo24HourFormat = (time) => {
    const [hour, minuteWithPeriod] = time.split(":");
    const [minute, period] = minuteWithPeriod.split(" ");

    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 < 12) hour24 += 12;
    if (period === "AM" && hour24 === 12) hour24 = 0;

    return `${hour24.toString().padStart(2, "0")}:${minute}:00`; // Add seconds for valid Date
  };

  const updateCountdown = () => {
    // Ensure eventDate and startTime exist before calculating
    if (!eventData?.eventDate || !eventData?.eventStartTime) return;

    // Convert eventStartTime to 24-hour format
    const eventStartTime24 = convertTo24HourFormat(eventData.eventStartTime);

    // Combine event date and start time
    const eventStartDateTime = new Date(
      `${eventData.eventDate}T${eventStartTime24}`
    );
    const now = new Date().getTime();

    if (now >= eventStartDateTime.getTime()) {
      setIsEventStarted(true);
      return; // Stop further execution
    }
    const difference = eventStartDateTime.getTime() - now;

    if (difference < 0) {
      return;
    }

    // Calculate time left
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    setTimeLeft({ days, hours, minutes, seconds });
  };

  useEffect(() => {
    updateCountdown();
    const timer = setInterval(updateCountdown, 1000);
    return () => clearInterval(timer);
  }, [eventData]);

  const getContactDetails = () => {
    contactDetailsList()
      .then((res) => {
        const data = res?.data?.data[0];
        setContactData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getContactDetails();
  }, []);

  useEffect(() => {
    if (eventUrl) {
      getEventMasterDetails(eventUrl)
        .then((res) => {
          const response = res?.data?.data;
          setEventData(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, []);

  useEffect(() => {
    if (!eventData && eventUrl) {
      const formattedEventUrl = eventUrl;
      getEventMasterDetails(formattedEventUrl)
        .then((res) => {
          const response = res?.data?.data;
          setEventData(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [eventData, eventUrl]);

  const validate = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const contactPattern = /^\d+$/;

    for (const field in registerData) {
      if (field === "question") {
        continue;
      }
      if (!registerData[field]) {
        newErrors[field] = `Please enter ${field
          .replace(/([A-Z])/g, " $1")
          .toLowerCase()}.`;
      }
    }

    if (!emailPattern.test(registerData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!contactPattern.test(registerData.contactNumber)) {
      newErrors.contactNumber = "Please enter a valid contact number.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRegisterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validate(); // Validate on change
    setErrors({});
  };

  const handleSubmit = () => {
    if (!validate()) {
      return; // Do not submit if there are validation errors
    }
    try {
      const payload = { ...registerData, ["eventIdForUser"]: eventData?._id };
      const result = submitRegisteredEventUser(payload);

      Toast(result).then((res) => {
        if (res?.data?.status === true) {
          setRegisterData({
            firstName: "",
            lastName: "",
            email: "",
            contactNumber: "",
            company: "",
            title: "",
            question: "",
          });
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const settings = {
    dots: true,
    infinite: eventData?.image.length <= 1 ? false : true,
    speed: 500,
    slidesToShow: 1, // Always show one image
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Fast autoplay if only one image
    arrows: true,
    nav: true,
  };

  const getYouTubeEmbedUrl = (url) => {
    if (!url) return "";

    // Extract video ID from standard, shortened, or share URLs
    const videoIdMatch = url?.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&?\/]+)/
    );
    if (!videoIdMatch) return "";

    const videoId = videoIdMatch[1];

    return `https://www.youtube.com/embed/${videoId}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  return (
    <div className="main">
      <div
        className="site-breadcrumb"
        style={{
          background: eventData?.eventBanner
            ? `url(${imageURL}${eventData?.eventBanner})`
            : "none", // Fallback background
        }}
      >
        <div className="container" style={{ marginTop: "120px" }}>
          <h2 className="breadcrumb-title">{eventData?.title}</h2>
        </div>
      </div>

      <div className="schedule-single py-120">
        <div className="container">
          <div className="schedule-single-wrap">
            <div className="row g-4">
              <div className="col-xl-8 col-lg-8">
                <div className="schedule-detail">
                  {eventData?.image && (
                    <div className="schedule-detail-img mb-30">
                      <Slider {...settings}>
                        {eventData?.image?.map((img, index) => (
                          <div key={index} className="event-slider">
                            <img
                              src={`${imageURL}${img}`}
                              alt={`Event image ${index + 1}`}
                              className="event-image "
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  )}
                  <div className="schedule-detail-info">
                    <h3 className="mb-20">{eventData?.title}</h3>
                    <p
                      className="mb-20 contents"
                      dangerouslySetInnerHTML={{
                        __html: eventData?.description
                          ? JSON.parse(eventData.description)
                          : "",
                      }}
                    ></p>

                    {eventData?.link && (
                      <div className="video-area">
                        <div className="video-content">
                          <div className="video-wrapper">
                            <iframe
                              width="100%"
                              height="100%"
                              src={getYouTubeEmbedUrl(eventData?.link)}
                              title="YouTube video player"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    )}

                    <p
                      className="mb-20 mt-20 contents"
                      dangerouslySetInnerHTML={{
                        __html:
                          eventData?.eventVideoDescription &&
                          eventData?.eventVideoDescription
                            ? JSON.parse(eventData?.eventVideoDescription)
                            : "",
                      }}
                    ></p>
                  </div>
                </div>
                {eventData?.speakers.length !== 0 && (
                  <div className="my-4">
                    <h3 className="mb-4">Event Speakers</h3>
                    <div className="row g-4">
                      {eventData?.speakers.map((item, index) => (
                        <div className="col-6 col-lg-3" key={index}>
                          <div
                            className="team-item"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              data-tooltip-id={`tooltip-${index}`}
                              data-tooltip-content={item?.speakerinfo || ""}
                              className="team-img"
                            >
                              <img alt="" src={`${imageURL}${item.image}`} />
                            </div>
                            <Tooltip
                              id={`tooltip-${index}`}
                              place="top"
                              effect="solid"
                              className="custom-tooltip" // Add your custom class here
                            />

                            <div className="team-content">
                              <div className="info">
                                <h4>{item.name}</h4>
                                <span>{item.designation}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="col-xl-4 col-lg-4">
                <div className="schedule-sidebar">
                  {!isEventStarted && (
                    <div className="widget">
                      <h4 className="widget-title">Countdown</h4>
                      <div className="event-countdown">
                        <div className="event-countdown-wrap">
                          <div
                            data-countdown="2030/01/30"
                            className="event-countdown"
                          >
                            <div className="time-wrap">
                              <span className="time">
                                <span>{timeLeft.days}</span>
                                <span className="unit">Days</span>
                              </span>
                              <span className="divider">:</span>
                              <span className="time">
                                <span>{timeLeft.hours}</span>
                                <span className="unit">Hours</span>
                              </span>
                              <span className="divider">:</span>
                              <span className="time">
                                <span>{timeLeft.minutes}</span>
                                <span className="unit">Mins</span>
                              </span>
                              <span className="divider">:</span>
                              <span className="time">
                                <span>{timeLeft.seconds}</span>
                                <span className="unit">Secs</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="widget">
                    <h4 className="widget-title">Event Info</h4>
                    <div className="schedule-info-list">
                      <ul>
                        <li>
                          <div className="icon">
                            <i className="far fa-clock"></i>
                          </div>
                          <div className="content">
                            <span>Time</span>
                            <h6>
                              {eventData?.eventStartTime
                                ? eventData?.eventStartTime
                                : ""}
                              -
                              {eventData?.eventEndTime
                                ? eventData?.eventEndTime
                                : ""}{" "}
                              {eventData?.timeZone ? eventData?.timeZone : ""}
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="far fa-calendar-days"></i>
                          </div>
                          <div className="content">
                            <span>Date</span>
                            <h6>
                              {eventData?.eventDate
                                ? formatDate(eventData?.eventDate)
                                : "-"}
                            </h6>
                          </div>
                        </li>
                        {eventData?.eventLocation && (
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (eventData?.eventLocationWebsite) {
                                window.open(
                                  eventData?.eventLocationWebsite,
                                  "_blank"
                                );
                              }
                            }}
                          >
                            <div className="icon">
                              <i className="far fa-location-dot"></i>
                            </div>
                            <div className="content">
                              <span>Location</span>
                              <h6>
                                {eventData?.eventLocation
                                  ? eventData?.eventLocation
                                  : "-"}
                              </h6>
                            </div>
                          </li>
                        )}

                        {eventData?.website && eventData.website.length > 0 && (
                          <li
                            style={{
                              alignItems: "start",
                            }}
                          >
                            <div className="icon">
                              <i className="fas fa-link"></i>
                            </div>
                            <div className="content">
                              <span>Sponsored By</span>
                              {eventData.website.map((link, index) => (
                                <h6 key={index} style={{ margin: 0 }}>
                                  <a
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "#007bff",
                                      wordBreak: "break-all", // Wraps long URLs
                                    }}
                                  >
                                    {link}
                                  </a>
                                  <br />
                                  <br />
                                </h6>
                              ))}
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {eventData?.eventAgenda && (
                  <div className="schedule-sidebar">
                    <div className="widget">
                      <h4 className="widget-title">Agenda</h4>
                      <div className=" contact-form-header">
                        <div className="row">
                          <div className="col-md-12">
                            <span
                              style={{ color: "black", whiteSpace: "pre-line" }}
                            >
                              {eventData?.eventAgenda}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="schedule-sidebar">
                  <div className="widget contact-form">
                    <h4 className="widget-title">Register Now</h4>
                    <div className=" contact-form-header">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="far fa-user-tie"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              placeholder="First Name"
                              required
                              value={registerData.firstName}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.firstName && (
                            <div style={{ color: "red" }}>
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="far fa-user-tie"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              placeholder="Last Name"
                              required
                              value={registerData.lastName}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.lastName && (
                            <div style={{ color: "red" }}>
                              {errors.lastName}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="far fa-user-tag"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Title"
                              required
                              value={registerData.title}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.title && (
                            <div style={{ color: "red" }}>{errors.title}</div>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fas fa-building"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              name="company"
                              placeholder="Company Name"
                              required
                              value={registerData.company}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.company && (
                            <div style={{ color: "red" }}>{errors.company}</div>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fas fa-phone"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              name="contactNumber"
                              placeholder="Contact Number"
                              required
                              value={registerData.contactNumber}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.contactNumber && (
                            <div style={{ color: "red" }}>
                              {errors.contactNumber}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="far fa-envelope"></i>
                            </span>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email Address"
                              required
                              value={registerData.email}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.email && (
                            <div style={{ color: "red" }}>{errors.email}</div>
                          )}
                        </div>
                        {eventData?.question && (
                          <div className="col-md-12">
                            <label
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                marginLeft: "10px",
                              }}
                            >
                              {eventData?.question}
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="far fa-pen"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                name="question"
                                placeholder="Your Answer"
                                required
                                value={registerData?.question}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <button
                        type="submit"
                        className="theme-btn"
                        onClick={handleSubmit}
                      >
                        Register <i className="far fa-paper-plane"></i>
                      </button>
                      <div className="col-md-12 mt-3">
                        <div className="form-messege text-success"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="schedule-sidebar">
                  <div className="widget">
                    <h4 className="widget-title">Organizer</h4>
                    <div className="schedule-list">
                      <ul className="event-organizer">
                        <li>
                          <Link to="tel:+21236547898">
                            <i className="far fa-phone" />
                            {contactData?.contactus}
                          </Link>
                        </li>
                        <li style={{ display: "flex", alignItems: "center" }}>
                          <i className="far fa-map-marker-alt" />
                          <div>{contactData?.address}</div>
                        </li>
                        <li>
                          <Link to={`mailto:${contactData?.email}`}>
                            <i className="far fa-envelope" />
                            {contactData?.email}
                          </Link>
                        </li>
                      </ul>

                      <div className="social" style={{ textAlign: "left" }}>
                        <ul style={{ display: "flex" }}>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.linkedin.com/company/suitelink/"
                            >
                              <i className="fab fa-linkedin-in" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.instagram.com/suite_link/"
                            >
                              <i className="fab fa-instagram" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                            </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {eventData?.sponsors?.length > 0 && (
        <div className="partner-area partner-bg py-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="site-heading text-center wow fadeInDown"
                  data-wow-delay=".25s"
                >
                  <h2 className="site-title">
                    <span> Sponsored</span> By
                  </h2>
                </div>
              </div>
            </div>
            <div className="partner-wrapper wow fadeInUp" data-wow-delay=".25s">
              <div className="row g-5 justify-content-center">
                {eventData?.sponsors?.map((img, index) => (
                  <div key={index} className="col-6 col-md-2">
                    <a
                      href={img.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${imageURL}${img.images}`}
                        alt={`Sponsers image ${index + 1}`}
                        className="sponsers-image "
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="text-center mt-40 wow fadeInUp"
              data-wow-delay=".25s"
            >
              <a href="#" className="theme-btn" target="_blank">
                <span className="fal fa-users"></span> Powered by SuiteLink
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
