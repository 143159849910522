import React from "react";
import logoLight from "../../assets/img/logo/logo-white.png";
import logo from "../../assets/img/logo/logo-blue.png";
import { Link } from "react-router-dom";
const header = () => {
  return (
    <header className="header">
      <div className="main-navigation">
        <nav className="navbar navbar-expand-lg">
          <div className="container position-relative">
            <Link to="/" className="navbar-brand">
              <img src={logoLight} className="logo-display" alt="logo" />
              <img src={logo} className="logo-scrolled" alt="logo" />
            </Link>
            <div className="mobile-menu-right">
              <div className="search-btn">
                <button
                  type="button"
                  className="nav-right-link search-box-outer"
                >
                  <i className="far fa-search" />
                </button>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-mobile-icon">
                  <i className="far fa-bars" />
                </span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="main_nav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    data-bs-toggle="dropdown"
                  >
                    Services
                  </Link>
                  <ul className="dropdown-menu fade-down">
                    <li>
                      <Link className="dropdown-item" to="/events">
                        Overview
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/services/lunch">
                        Lunch
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/services/dinner">
                        Dinner{" "}
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/services/sports">
                        Sports{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default header;
